import React, { Fragment } from 'react'
import { Menu as MenuAnt, Icon } from 'antd'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import LogoComplete from './LogoComplete'
import Logout from 'user/Logout'
import { useIsSideCollapsedGetter, useProfileValue } from 'redux/reducers'
import { CONTACT_URL } from 'environment'

/*language=SCSS*/
const MenuStyled = styled.div`
  &.p-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 15px);
    color: #ddd;
  }
  .p-brand {
    margin: 4px 0 20px 20px;
  }
  .p-user-wrapper {
    color: rgba(0, 0, 0, 0.65);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 10px 30px;
    .user-organisation {
      font-size: 10px;
      margin-bottom: 20px;
    }
    button {
      width: 100%;
    }
  }
`

const MenuAuthenticated = () => {
  const isSideCollapsed = useIsSideCollapsedGetter()
  const profile = useProfileValue()
  const location = useLocation()
  const menuItems = [
    {
      path: '/',
      icon: 'home',
      label: 'Mortgages',
    },
    {
      path: '/contracts',
      icon: 'file-done',
      label: 'Contracts',
    },
    {
      path: '/me',
      icon: 'user',
      label: 'Profile',
    },
    // {
    //   path: '/documents',
    //   icon: 'book',
    //   label: 'Terms, procedures',
    // },
    {
      path: CONTACT_URL,
      isAbsolutePath: true,
      icon: 'form',
      label: 'Support',
    },
  ]
  return (
    <MenuStyled className="p-menu">
      {!isSideCollapsed && (
        <div className="p-brand">
          <LogoComplete />
        </div>
      )}
      {!profile.isAnonymous && (
        <MenuAnt
          theme="light"
          mode="inline"
          selectedKeys={[`${menuItems.findIndex(item => item.path === location.pathname)}`]}
        >
          {menuItems.map((item, index) => {
            const content = (
              <Fragment>
                <Icon type={item.icon} />
                <span className="nav-text">{item.label}</span>
              </Fragment>
            )
            return (
              <MenuAnt.Item key={index}>
                {!item.isAbsolutePath ? (
                  <Link to={item.path} children={content} />
                ) : (
                  <a href={item.path} target="new" children={content} />
                )}
              </MenuAnt.Item>
            )
          })}
        </MenuAnt>
      )}
      {!isSideCollapsed && (
        <div className="p-user-wrapper">
          <div>
            <div className="user-name">{profile.name}</div>
            <div className="user-organisation">
              {profile.organisation.name} {profile.isInvestor ? '(investor)' : '(bank)'}
            </div>
            <Logout />
          </div>
        </div>
      )}
    </MenuStyled>
  )
}

export default MenuAuthenticated
