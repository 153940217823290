import { useApolloClient } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { normaliseGraphQLResponse } from '../helpers'
import { useLoadingAuthValue, useProfileSetter, useUserValue } from 'redux/reducers'
import { useEffect } from 'react'

const USER_TYPE_BANK = 1
const USER_TYPE_INVESTOR = 2

const GET_PROFILE = gql`
  query GetProfile($id: String!) {
    user_by_pk(id: $id) {
      id
      name
      organisation {
        id
        name
        type
      }
    }
  }
`

function ProfileFetcher() {
  const user = useUserValue()
  const loadingAuth = useLoadingAuthValue()
  const setProfile = useProfileSetter()
  const apolloClient = useApolloClient()

  useEffect(() => {
    if (loadingAuth) {
      setProfile({ isAnonymous: true, isLoading: true })
    } else if (user) {
      apolloClient
        .query({
          query: GET_PROFILE,
          variables: { id: user.uid },
        })
        .then(({ data }) => {
          const rawProfile = data.user_by_pk && normaliseGraphQLResponse(data.user_by_pk)
          setProfile(
            !rawProfile
              ? { isAnonymous: true, isLoading: false }
              : {
                  ...rawProfile,
                  isBank: rawProfile.organisation.type === USER_TYPE_BANK,
                  isInvestor: rawProfile.organisation.type === USER_TYPE_INVESTOR,
                  isAnonymous: false,
                  isLoading: false,
                }
          )
        })
        .catch(err => {
          // console.log("Can't fetch profile, must be authenticated")
        })
    } else {
      setProfile({ isAnonymous: true, isLoading: false })
    }
  }, [user, apolloClient, loadingAuth])

  return null
}

export default ProfileFetcher
