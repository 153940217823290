import React from 'react'
import { Card, Icon } from 'antd'
import styled from 'styled-components'
import { useTitleSetter, useProfileValue, useUserValue } from 'redux/reducers'

/* language=SCSS */
const ProfileStyled = styled.div``

function Profile() {
  const setTitle = useTitleSetter()
  const user = useUserValue()
  const profile = useProfileValue()
  setTitle('My Profile')

  if (user && profile.organisation) {
    console.log('user id', user.uid)
    console.log('organisation id', profile.organisation.id)
  }

  return (
    !profile.isAnonymous && (
      <ProfileStyled>
        <Card
          title={
            <div>
              <Icon type="user" /> {profile.name}
            </div>
          }
        >
          <Card.Meta
            // avatar={<Icon type="user" />}
            title={user.email}
            description={
              <p>
                Belonging to {profile.organisation.type === 1 ? 'bank' : 'investor'}
                <em> {profile.organisation.name}</em>
              </p>
            }
          />
        </Card>
      </ProfileStyled>
    )
  )
}

export default Profile
