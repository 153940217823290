// @flow
const isObject = function(o) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function'
}

/**
 * helper function, applies the callback on keys
 *
 * @param originalObject
 * @param callback  The callback to apply on keys, to have them renamed or more.
 *  Depending on the return, the following actions are taken:
 * 1. non-empty string: the key is renamed
 * 2. null: the key&value pair is removed
 * 3. '': the key is squashed, the content is moved to the parent. Don't use this option
 * unless the child is unique (otherwise, the last key encountered will set the value of the
 * parent; and because the objects are not ordered, the results are less predictable
 */
function deepMap(
  originalObject: Object,
  callback: ({ key: string, value: string }) => { key: string | null, value: any }
) {
  // function deepMap(originalObject: Object, callback: string => string | null) {
  if (isObject(originalObject)) {
    let newObject = {}
    Object.keys(originalObject).forEach(oldKey => {
      const { key, value } = callback({ key: oldKey, value: originalObject[oldKey] })
      if (key) {
        // rename key
        newObject[key] = deepMap(value, callback)
      } else if (key === '') {
        // squash key (put content in the parent)
        const newValue = deepMap(value, callback)
        if (isObject(newValue)) {
          Object.assign(newObject, newValue)
        } else {
          newObject = newValue
        }
      }
      // else: do nothing, the key is skipped (aka deleted)
    })
    return newObject
  } else if (Array.isArray(originalObject)) {
    return originalObject.map(arrayItem => {
      return deepMap(arrayItem, callback)
    })
  }

  return originalObject
}

const toCamel = s => {
  return s.replace(/([-_][a-z1-9])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  })
}

// make a graphql result into a UI-friendly object by:
// 1. flattening i18n entries and aggregates (squash node and en/de)
// 2. squashing unwanted keys
// 3. camelCase
//  -- step disabled: 4. removing aggregate suffixes
// 5. remove __typename
// Important: with this approach, do not use queries like
// mortgagePools { mortgages_aggregate { aggregate {...}, nodes {...} } }
export const normaliseGraphQLResponse = (obj: Object, keysToSquash?: [string] = ['']) =>
  deepMap(obj, ({ key, value }) => {
    // get rid of intermediary nodes
    if (['en', 'de', 'nodes', 'aggregate', ...keysToSquash].includes(key)) {
      return { key: '', value }
    }
    if (key === '__typename') {
      return { key: null, value }
    }
    // keep suffix _aggregate after all
    // return { key: toCamel(key.replace('_aggregate', '')), value }
    return { key: toCamel(key), value }
  })
