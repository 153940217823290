import React from 'react'
import { Form, Button } from 'antd'
import { firebaseAuth } from './useAuth'

const logout = firebaseAuth.signOut.bind(firebaseAuth)

const Logout = () => {
  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        logout()
      }}
    >
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Logout
        </Button>
      </Form.Item>
    </Form>
  )
}
export default Logout
