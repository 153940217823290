import React from 'react'
import { Route } from 'react-router'
// import { Redirect } from 'react-router-dom'
import Spin from 'layout/Spinner'
import { useProfileValue, useLoadingAuthValue } from 'redux/reducers'
import NotAllowed from './NotAllowed'

const PrivateRoute = ({ component: Component, fallback: Fallback, redirect, ...rest }) => {
  const profile = useProfileValue()
  const loadingAuth = useLoadingAuthValue()

  if (loadingAuth) return <Spin tip="Authenticating..." />
  if (profile.isLoading) return <Spin tip="Loading profile..." />

  if (!profile.isAnonymous) {
    return <Route {...rest} render={props => <Component {...props} />} />
  } else if (Fallback) {
    return <Route {...rest} render={props => <Fallback {...props} />} />
  } else {
    return (
      <NotAllowed />
      // <Route
      //   {...rest}
      //   render={props => (
      //     <Redirect
      //       to={{
      //         pathname: redirect ? redirect : '/',
      //         state: { from: props.location },
      //       }}
      //     />
      //   )}
      // />
    )
  }
}

export default PrivateRoute
