import autodux from 'autodux'
import { combineReducers } from 'redux'
import { createSimpleStateHookPair } from './helpers'

const layout = autodux({
  slice: 'layout',
  initial: {
    isSideCollapsed: false,
    title: null,
  },
  // actions: { setTitle: (state, title) => ({ ...state, title }) },
})
export const [useIsSideCollapsedGetter, useIsSideCollapsedSetter] = createSimpleStateHookPair(
  layout,
  'isSideCollapsed'
)
export const [useTitleGetter, useTitleSetter] = createSimpleStateHookPair(layout, 'title')

const auth = autodux({
  slice: 'auth',
  initial: {
    user: null,
    loadingAuth: true,
    token: null,
    profile: { isAnonymous: true, isLoading: true },
  },
})
export const [useUserValue, useUserSetter] = createSimpleStateHookPair(auth, 'user')
export const [useProfileValue, useProfileSetter] = createSimpleStateHookPair(auth, 'profile')
export const [useTokenValue, useTokenSetter] = createSimpleStateHookPair(auth, 'token')
export const [useLoadingAuthValue, useLoadingAuthSetter] = createSimpleStateHookPair(
  auth,
  'loadingAuth'
)

export const rootReducer = combineReducers({
  layout: layout.reducer,
  auth: auth.reducer,
})
