import React from 'react'
import { useTitleSetter } from '../redux/reducers'
import { Result, Button } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

/* language=SCSS */
const NotFoundStyled = styled.div``

const NotFound = ({ showHomeLink = false }) => {
  const setTitle = useTitleSetter()
  setTitle('Page not found')
  return (
    <NotFoundStyled>
      <Result
        status="404"
        title="Something's wrong here"
        subTitle="We can't find the page you are looking for."
        extra={
          showHomeLink ? (
            <Link className="p-home-link" to="/">
              <Button type="primary">Back Home</Button>
            </Link>
          ) : null
        }
      />
    </NotFoundStyled>
  )
}

export default NotFound
