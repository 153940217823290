import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'
import { useTitleGetter } from 'redux/reducers'

// language=SCSS
const HeaderStyled = styled(Layout.Header)`
  &.p-header {
    padding: 0 20px;
    background: #1890ff;
    h1 {
      color: white;
      font-size: 22px;
    }
  }
`

const Header = () => {
  const title = useTitleGetter()
  return !title ? null : (
    <HeaderStyled className="p-header">
      <h1>{title}</h1>
    </HeaderStyled>
  )
}

export default Header
