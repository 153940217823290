import React from 'react'
import { Image, Transformation } from 'cloudinary-react'

const LogoComplete = ({ width = 140, className }) => (
  <Image publicId="prolendo_logo_complete_8bit" className={className}>
    <Transformation width={width} crop="scale" />
  </Image>
)

export default LogoComplete
