import useRedux from '@mollycule/redux-hook'

const noop = () => {}
// Take obj like { a: 121 } and return 121
const flattenSimpleObject = obj => obj[Object.keys(obj)[0]]

// Creates a pair of hooks - a getter and setter for a variable in the global state.
// 2 hooks instead of 1 to prevent situations like when a component depends
// on a state value, when it actually wanted to only set that value
export const createSimpleStateHookPair = (autoduxVariable, stateName) => {
  const capitalisedStateName = stateName.replace(/./, stateName[0].toUpperCase())
  const getterName = `get${capitalisedStateName}`
  const setterName = `set${capitalisedStateName}`
  return [
    () =>
      flattenSimpleObject(
        useRedux(
          state => ({
            [stateName]: autoduxVariable.selectors[getterName](state),
          }),
          noop
        )
      ),
    () =>
      flattenSimpleObject(
        useRedux(noop, dispatch => ({
          [setterName]: stateValue => dispatch(autoduxVariable.actions[setterName](stateValue)),
        }))
      ),
  ]
}
