import React from 'react'
import { Alert } from 'antd'
import styled from 'styled-components'
import Login from 'user/Login'
import { useTitleSetter } from 'redux/reducers'
import LogoComplete from 'layout/LogoComplete'
import { CONTACT_URL } from 'environment'

/* language=SCSS */
const NotAllowedStyled = styled.div`
  & {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
  }
  .ant-alert {
    margin-top: 30px;
  }
  .logo {
    margin-bottom: 50px;
  }
`

const NotAllowed = () => {
  const setTitle = useTitleSetter()
  setTitle()
  return (
    <NotAllowedStyled>
      <LogoComplete width={400} className="logo" />
      <Login />
      <Alert
        message="You must login to use the Prolendo platform"
        description={
          <p>
            For registrations, please{' '}
            <a href={CONTACT_URL} target="new">
              contact us
            </a>
          </p>
        }
        type="warning"
        showIcon
      />
    </NotAllowedStyled>
  )
}

export default NotAllowed
