import { useEffect } from 'react'
import firebase from '@firebase/app'
import 'firebase/auth'
import firebaseConfig from './firebaseConfig'
import { useUserSetter, useLoadingAuthSetter, useTokenSetter } from 'redux/reducers'

firebase.initializeApp(firebaseConfig)
export const firebaseAuth = firebase.auth()

const useAuth = () => {
  const setToken = useTokenSetter()
  const setUser = useUserSetter()
  const setLoadingAuth = useLoadingAuthSetter()

  // registers a handler to firebase user changes
  // (should happen only once, and should be never cleaned up)
  useEffect(() => {
    // NB: onAuthStateChanged returns a cleanup callback,
    // if we'd return it, this listener would stop after the first redirection
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const token = await user.getIdToken()
        const idTokenResult = await user.getIdTokenResult()
        const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims']

        if (hasuraClaim) {
          setToken(token)
        } else {
          // Check if refresh is required.
          const metadataRef = firebase.database().ref('metadata/' + user.uid + '/refreshTime')

          metadataRef.on('value', async () => {
            // Force refresh to pick up the latest custom claims changes.
            const token = await user.getIdToken(true)
            setToken(token)
          })
        }
      } else {
        setToken(null)
      }
      setUser(user)
      setLoadingAuth(false)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useAuth
