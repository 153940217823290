import React from 'react'
import { Affix, Layout as LayoutAntd } from 'antd'
import styled from 'styled-components'
import MenuAuthenticated from './MenuAuthenticated'
import { useIsSideCollapsedSetter, useProfileValue } from 'redux/reducers'
import Header from './Header'

const { Content, Footer, Sider } = LayoutAntd

// language=SCSS
const LayoutStyled = styled(LayoutAntd)`
  & {
    min-height: 100%;
    height: inherit;
  }
  .p-affix,
  .p-menu-wrapper {
    height: 100%;
  }
  .p-content-wrapper {
    min-height: initial;
    margin: 24px 16px 0;
  }
  .p-content {
    padding: 24px;
    background: #fff;
    //min-height: 360px;
    height: 100%;
  }
  .p-footer {
    text-align: center;
  }
`

const Layout = ({ children }) => {
  const profile = useProfileValue()
  const setIsSideCollapsed = useIsSideCollapsedSetter()
  return (
    <LayoutStyled>
      {!profile.isAnonymous && (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={isBroken => {
            setIsSideCollapsed(isBroken)
          }}
          theme="light"
        >
          <Affix offsetTop={15} className="p-affix">
            {/* necessary div, taking the refs from Affix (doesn't work with the functional comp) */}
            <div className="p-menu-wrapper">
              <MenuAuthenticated />
            </div>
          </Affix>
        </Sider>
      )}
      <LayoutAntd>
        <Header />
        <Content className="p-content-wrapper">
          <div className="p-content">{children}</div>
        </Content>
        <Footer className="p-footer">Prolendo ©2019</Footer>
      </LayoutAntd>
    </LayoutStyled>
  )
}

export default Layout
