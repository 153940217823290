import React, { Fragment } from 'react'
import styled from 'styled-components'
import Spin from 'layout/Spinner'
import { createStoreContext } from '@mollycule/redux-hook'
import { BrowserRouter as Router } from 'react-router-dom'
import reduxStore from './redux/store'
import ApolloProvider from './ApolloProvider'
import useAuth from 'user/useAuth'
import { LayoutAuthenticated } from './layout'
import Routes from './router/Routes'
import { useLoadingAuthValue, useProfileValue } from 'redux/reducers'
import ProfileFetcher from './user/ProfileFetcher'
import 'antd/dist/antd.css'
import { CloudinaryContext } from 'cloudinary-react'

/* language=SCSS */
const AppStyled = styled.div`
  &,
  .cloudinary-context {
    min-height: 100%;
    height: inherit;
  }
`

// using several separated components because of running the hooks

const App = () => {
  return (
    <CloudinaryContext cloudName="prolendo" className="cloudinary-context">
      <Router>
        <LayoutAuthenticated>
          <Routes />
        </LayoutAuthenticated>
      </Router>
    </CloudinaryContext>
  )
}

const ApolloAndAuthWrapper = () => {
  useAuth()
  return (
    <ApolloProvider>
      <Fragment>
        <ProfileFetcher />
        <App />
      </Fragment>
    </ApolloProvider>
  )
}

const { Provider: ReduxProvider } = createStoreContext()

const ReduxAndCSSWrapper = () => (
  <AppStyled id="App">
    <ReduxProvider store={reduxStore}>
      <ApolloAndAuthWrapper />
    </ReduxProvider>
  </AppStyled>
)

export default ReduxAndCSSWrapper
