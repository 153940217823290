import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

// language=SCSS
const SpinnerStyled = styled.div`
  & {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const Spinner = ({ tip }) => (
  <SpinnerStyled className="spinner-centered">
    <Spin tip={tip} />
  </SpinnerStyled>
)

export default Spinner
