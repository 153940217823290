import React, { useEffect, useState } from 'react'
import { ApolloClient } from 'apollo-client'
import { ApolloProvider as VanillaApolloProvider } from '@apollo/react-hooks'
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getMainDefinition } from 'apollo-utilities'
import { useTokenValue } from 'redux/reducers'
import { HTTP_ENDPOINT, WS_ENDPOINT } from 'environment'

const createApolloClient = token => {
  // Create an http link:
  const httpLink = new HttpLink({
    uri: HTTP_ENDPOINT,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
  })

  // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: WS_ENDPOINT,
    options: {
      reconnect: true,
      // lazy: true,
      timeout: 30000,
      // note: this can also be a function, even w/ promise, like  `async () => ({ headers: ... })`
      connectionParams: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    },
  })

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    httpLink
  )

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
}

const ApolloProvider = ({ children }) => {
  const token = useTokenValue()
  const [apolloClient, setApolloClient] = useState()

  // recreate the https&wss links every time the user changes
  useEffect(() => {
    try {
      if (token) console.log(`Bearer ${token}`)
      setApolloClient(createApolloClient(token))
    } catch (e) {
      console.log(e)
    }
  }, [token])

  return apolloClient ? (
    <VanillaApolloProvider client={apolloClient}>{children}</VanillaApolloProvider>
  ) : null
}

export default ApolloProvider
