import React, { useState } from 'react'
import { Alert, Table, Button } from 'antd'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import styled from 'styled-components'
import Spin from 'layout/Spinner'
import { normaliseGraphQLResponse } from '../helpers'
import { useTitleSetter } from '../redux/reducers'
import { Link } from 'react-router-dom'

/* language=SCSS */
const ContractsStyled = styled.div`
  .p-expand-collapse {
    margin-bottom: 20px;
  }
  .p-child-table {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 2px 5px;
      font-size: 12px;
    }
  }
`

const GET_CONTRACTS = gql`
  query GetContracts {
    contract {
      id
      created_at
      mortgage_pool {
        id
        name
        organisation {
          id
          name
        }
        mortgages_aggregate {
          aggregate {
            sum {
              value
            }
          }
        }
      }
      payments {
        id
        due_date
        amount
        class
        status
        transactions_aggregate {
          aggregate {
            sum {
              amount
            }
          }
        }
      }
    }
  }
`

// if a row is expanded, collapses it, otherwise expands it
function handleRowExpand(record, expandedRows, setExpandedRows) {
  setExpandedRows(
    expandedRows.includes(record.key)
      ? expandedRows.filter(key => key !== record.key)
      : [...expandedRows, record.key]
  )
}

function Contracts() {
  const setTitle = useTitleSetter()
  const [expandedRows, setExpandedRows] = useState([])

  const { loading, error, data } = useQuery(GET_CONTRACTS)

  setTitle('Contracts')
  if (loading) return <Spin tip="Loading contracts..." />
  if (error) return <Alert message={error.message} type="error" />

  const contracts = normaliseGraphQLResponse(data.contract)

  if (contracts.length === 0) {
    return <Alert message="You have no contracts yet." type="info" />
  }
  const mainColumns = [
    {
      title: 'Pool',
      dataIndex: 'poolName',
      key: 'poolName',
      render: (text, contract) => (
        <Link to={`/pool/${contract.allData.mortgagePool.id}`}>{text}</Link>
      ),
    },
    { title: 'Pool value (EUR)', dataIndex: 'poolValue', key: 'poolValue' },
    { title: 'Originator', dataIndex: 'originator', key: 'originator' },
    { title: 'Sign date', dataIndex: 'signedAt', key: 'signedAt' },
    {
      title: '',
      key: 'details',
      dataIndex: 'id',
      render: (text, { key }) => <Link to={`/contract/${key}`}>more</Link>,
    },
  ]
  const buysTableColumns = [
    {
      title: '',
      dataIndex: 'class',
      key: 'type',
      render: type => (type === 1 ? 'Pool buy' : 'Return'),
    },
    { title: 'Amount (EUR)', dataIndex: 'amount', key: 'amount' },
    { title: 'Due date', dataIndex: 'dueDate', key: 'dueDate' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        switch (status) {
          case 1:
            return 'Pending'
          case 2:
            return 'Completed'
          default:
            return 'Unknown'
        }
      },
    },
  ]

  const tableData = contracts.map(c => ({
    key: c.id,
    originator: c.mortgagePool.organisation.name,
    poolName: `#${c.mortgagePool.id}`, // c.mortgagePool.name,
    poolValue: c.mortgagePool.mortgagesAggregate.sum.value,
    signedAt: moment(c.createdAt).format('DD MMM YYYY'),
    allData: c,
  }))

  return (
    <ContractsStyled>
      <Button
        className="p-expand-collapse"
        onClick={() => setExpandedRows(!expandedRows.length ? tableData.map(c => c.key) : [])}
      >
        {!expandedRows.length ? 'Expand all rows' : 'Collapse all rows'}
      </Button>
      <Table
        columns={mainColumns}
        dataSource={tableData}
        pagination={contracts.length > 10 && 'bottom'}
        defaultExpandAllRows={false}
        expandedRowKeys={expandedRows}
        onExpand={(expanded, record) => handleRowExpand(record, expandedRows, setExpandedRows)}
        expandRowByClick
        expandedRowRender={({ allData: contract }) => {
          const { payments } = contract
          const dataSource = payments.map(p => ({ key: p.id, ...p }))
          return (
            <div>
              <Table
                className="p-child-table"
                columns={buysTableColumns}
                pagination={payments.length > 10 && 'bottom'}
                dataSource={dataSource}
              />
            </div>
          )
        }}
      />
    </ContractsStyled>
  )
}

export default Contracts
