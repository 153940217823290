const firebaseConfig = {
  apiKey: 'AIzaSyALJiP4TUduzWwIjySXHflVOQjEhcOuqDE',
  authDomain: 'prolendo-1.firebaseapp.com',
  databaseURL: 'https://prolendo-1.firebaseio.com',
  projectId: 'prolendo-1',
  storageBucket: 'prolendo-1.appspot.com',
  messagingSenderId: '20852907669',
  appId: '1:20852907669:web:2fb7bb86deefa4fdbfc59c',
  measurementId: 'G-GE11S5849J',
}

export default firebaseConfig
