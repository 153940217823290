import React from 'react'
import { Alert, Tabs } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import styled from 'styled-components'
import { normaliseGraphQLResponse } from '../helpers'
import { useTitleSetter, useProfileValue } from 'redux/reducers'
import PoolCard from './PoolCard'
import Spin from 'layout/Spinner'

const { TabPane } = Tabs

/* language=SCSS */
const MortgagePoolsStyled = styled.div``

const MORTGAGE_POOLS = gql`
  {
    mortgage_pool {
      id
      name
      organisation {
        id
        name
      }
      status
      mortgages_aggregate {
        aggregate {
          sum {
            value
          }
          max {
            duration
          }
          count(columns: id)
          avg {
            interest_rate
          }
        }
      }
      contracts(limit: 1) {
        created_at
        investor_id
      }
    }
  }
`

function Pools() {
  const { loading, error, data } = useQuery(MORTGAGE_POOLS)
  const profile = useProfileValue()
  const setTitle = useTitleSetter()

  setTitle('Mortgage Pools')
  if (loading || profile.isLoading) return <Spin tip="Loading mortgages..." />
  if (error) return <Alert message={error.message} type="error" />

  const pools = normaliseGraphQLResponse(data.mortgage_pool)

  return (
    <MortgagePoolsStyled>
      <Tabs>
        <TabPane tab="Bound" key="bound">
          {pools
            .filter(pool => pool.contracts.length)
            .map(pool => (
              <PoolCard key={pool.id} pool={pool} isInvestor={profile.isInvestor} />
            ))}
        </TabPane>
        <TabPane tab="Available" key="available">
          {pools
            .filter(
              pool =>
                !pool.contracts.length &&
                // todo: this should be preferably done in the backend
                (profile.isInvestor || pool.organisation.id === profile.organisation.id)
            )
            .map(pool => (
              <PoolCard key={pool.id} pool={pool} isInvestor={profile.isInvestor} />
            ))}
        </TabPane>
      </Tabs>
    </MortgagePoolsStyled>
  )
}

export default Pools
