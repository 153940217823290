import React from 'react'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router'
import PrivateRoute from './PrivateRoute'

import Pools from '../pools/Pools'
import Pool from 'pools/Pool'
import NotFound from './NotFound'
import Contracts from '../contracts/Contracts'
import Contract from '../contracts/Contract'
import Profile from '../user/Profile'

const Routes = () => {
  return (
    <Switch>
      {/*<PublicRoute path="/login" component={Login} exact />*/}
      <PrivateRoute path="/" component={Pools} exact />
      <PrivateRoute path="/pool/:id" component={Pool} exact />
      <PrivateRoute path="/contracts" component={Contracts} exact />
      <PrivateRoute path="/contract/:id" component={Contract} exact />
      <Route path="/me" component={Profile} exact />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
