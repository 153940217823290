import React from 'react'
import { Alert, Table, Descriptions, Row, Col, Button } from 'antd'
import moment from 'moment'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import { gql } from 'apollo-boost'
import styled from 'styled-components'
import Spin from 'layout/Spinner'
import { normaliseGraphQLResponse } from '../helpers'
import { useProfileValue, useTitleSetter } from '../redux/reducers'
import NotFound from '../router/NotFound'
import { Link } from 'react-router-dom'

const { Item } = Descriptions

/* language=SCSS */
const ContractStyled = styled.div`
  .p-aggregate,
  .p-action-wrapper {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .p-child-table {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 2px 5px;
      font-size: 12px;
    }
  }
`

const GET_CONTRACTS = gql`
  query GetContract($id: Int!) {
    contract_by_pk(id: $id) {
      id
      created_at
      investor {
        id
        name
      }
      mortgage_pool {
        id
        name
        organisation {
          id
          name
        }
        mortgages_aggregate {
          aggregate {
            sum {
              value
            }
          }
        }
      }
      payments {
        id
        due_date
        amount
        class
        status
        transactions {
          id
          created_at
          amount
        }
        transactions_aggregate {
          aggregate {
            sum {
              amount
            }
          }
        }
      }
    }
  }
`

function Contract() {
  const { id } = useParams()
  const setTitle = useTitleSetter()
  const { isInvestor, isBank } = useProfileValue()

  const { loading, error, data } = useQuery(GET_CONTRACTS, { variables: { id } })

  setTitle(
    `Contract ${(data &&
      data.contract_by_pk &&
      `for pool #${data.contract_by_pk.mortgage_pool.id}`) ||
      ''}`
  )
  if (loading) return <Spin tip="Loading contract..." />
  if (error) return <Alert message={error.message} type="error" />

  const contract = normaliseGraphQLResponse(data.contract_by_pk)

  if (!contract) {
    return <NotFound />
  }

  const mainColumns = [
    {
      title: '',
      dataIndex: 'class',
      key: 'type',
      render: type => (type === 1 ? 'Pool buy' : 'Return'),
    },
    { title: 'Amount (EUR)', dataIndex: 'amount', key: 'amount' },
    { title: 'Due date', dataIndex: 'dueDate', key: 'dueDate' },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        switch (status) {
          case 1:
            return 'Pending'
          case 2:
            return 'Completed'
          default:
            return 'Unknown'
        }
      },
    },
  ]

  return (
    <ContractStyled>
      <Row gutter={[0, 20]}>
        <Col md={18} xs={24}>
          <Descriptions bordered column={{ sm: 1, md: 2 }} className="p-aggregate">
            {isInvestor && (
              <Item label="Originator">{contract.mortgagePool.organisation.name}</Item>
            )}
            {isBank && <Item label="Investor">{contract.investor.name}</Item>}
            <Item label="Value">{contract.mortgagePool.mortgagesAggregate.sum.value} euro</Item>
          </Descriptions>
        </Col>
        <Col md={6} xs={24} className="p-action-wrapper">
          <Link to={`/pool/${contract.mortgagePool.id}`}>
            <Button type="primary" size="large" onClick={() => {}}>
              View pool
            </Button>
          </Link>
        </Col>
      </Row>

      <Table
        columns={mainColumns}
        dataSource={contract.payments.map(p => ({ key: p.id, ...p }))}
        pagination={contract.payments.length > 10 && 'bottom'}
        defaultExpandAllRows={true}
        expandRowByClick
        expandedRowRender={({ transactions }) => {
          return (
            <div>
              <Table
                className="p-child-table"
                columns={[
                  { title: 'Amount', dataIndex: 'amount', key: 'amount' },
                  { title: 'Date', dataIndex: 'executedAt', key: 'executedAt' },
                ]}
                pagination={transactions.length > 10 && 'bottom'}
                dataSource={transactions.map(t => ({
                  ...t,
                  key: t.id,
                  executedAt: moment(t.createdAt).format('DD MMM YYYY'),
                }))}
              />
            </div>
          )
        }}
      />
    </ContractStyled>
  )
}

export default Contract
