import React from 'react'
import { Card, Descriptions } from 'antd'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
const { Item } = Descriptions

/* language=SCSS */
const PoolCardStyled = styled(Card)`
  &.p-card {
    width: 100%;
    margin: 0 0 20px;
    .ant-card-body {
      padding: 0;
    }
    .ant-descriptions-view {
      border: none;
      tr:first-child > th {
        border-top: 1px solid #e8e8e8;
      }
    }
  }
`

const PoolCard = ({ pool, isInvestor }) => (
  <PoolCardStyled
    key={pool.id}
    className="p-card"
    title={`Pool #${pool.id}`}
    extra={<Link to={`pool/${pool.id}`}>More</Link>}
  >
    <Descriptions bordered column={2} className="p-aggregate">
      <Item label="Mortgages">{pool.mortgagesAggregate.count}</Item>
      <Item label="Value">{pool.mortgagesAggregate.sum.value} euro</Item>
      <Item label="Duration">{pool.mortgagesAggregate.max.duration} years</Item>
      <Item label="Interest rate">{pool.mortgagesAggregate.avg.interestRate}%</Item>
      {isInvestor && <Item label="Originator">{pool.organisation.name}</Item>}
    </Descriptions>
  </PoolCardStyled>
)

export default PoolCard
