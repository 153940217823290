import React from 'react'
import { Alert, Table, Descriptions, Row, Col, Button, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { normaliseGraphQLResponse } from '../helpers'
import { useTitleSetter, useProfileValue } from '../redux/reducers'
import Spin from 'layout/Spinner'

const { Item } = Descriptions

/* language=SCSS */
const PoolStyled = styled.div`
  .p-aggregate,
  .p-action-wrapper {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .p-card {
    width: 300px;
    margin: 0 0 20px;
  }
`

const MORTGAGE_POOL = gql`
  query GetPool($poolId: Int!) {
    mortgage_pool_by_pk(id: $poolId) {
      id
      name
      organisation {
        id
        name
      }
      status
      mortgages_aggregate {
        aggregate {
          sum {
            value
          }
          max {
            duration
          }
          count(columns: id)
          avg {
            interest_rate
          }
        }
      }
      mortgages {
        id
        duration
        covered_percent
        interest_rate
        value
      }
      contracts {
        id
      }
    }
  }
`

function Pool() {
  const { id } = useParams()
  const history = useHistory()
  const setTitle = useTitleSetter()
  const { isInvestor } = useProfileValue()

  const { loading, error, data } = useQuery(MORTGAGE_POOL, {
    variables: { poolId: id },
  })

  setTitle(`Mortgage Pool #${id}`)
  if (loading) return <Spin tip="Loading pool details..." />
  if (error) return <Alert message={error.message} type="error" />

  const pool = normaliseGraphQLResponse(data.mortgage_pool_by_pk)

  const fieldsAndLabels = {
    value: 'Value (EUR)',
    interestRate: 'Interest (%)',
    coveredPercent: 'Coverage (%)',
    duration: 'Duration (years)',
  }
  const columns = Object.keys(fieldsAndLabels).map(fieldName => ({
    title: fieldsAndLabels[fieldName],
    dataIndex: fieldName,
    key: fieldName,
  }))

  return (
    <PoolStyled>
      <Row gutter={[0, 20]}>
        <Col md={18} xs={24}>
          <Descriptions bordered column={{ sm: 1, md: 2 }} className="p-aggregate">
            <Item label="Number of mortgages">{pool.mortgagesAggregate.count}</Item>
            <Item label="Value">{pool.mortgagesAggregate.sum.value} euro</Item>
            <Item label="Duration">{pool.mortgagesAggregate.max.duration} years</Item>
            <Item label="Interest rate">{pool.mortgagesAggregate.avg.interestRate}%</Item>
            {isInvestor && <Item label="Originator">{pool.organisation.name}</Item>}
          </Descriptions>
        </Col>
        <Col md={6} xs={24} className="p-action-wrapper">
          {!pool.contracts.length && isInvestor && (
            <Button
              type="primary"
              size="large"
              onClick={() =>
                Modal.confirm({
                  title: `Invest in pool #${pool.id}`,
                  centered: true,
                  okText: 'Confirm',
                  okType: '',
                  content: (
                    <div>
                      <p>
                        By confirming now, you agree to Prolendo's terms and conditions of
                        investing.
                      </p>
                      <p>We'll contact you within two business days regarding the next steps.</p>
                    </div>
                  ),
                  // onOk() {},
                })
              }
            >
              Apply to invest
            </Button>
          )}
          {!!pool.contracts.length && (
            <Button
              type="primary"
              size="large"
              onClick={() => history.push(`/contract/${pool.contracts[0].id}`)}
            >
              View contract
            </Button>
          )}
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={pool.mortgages.map(mg => ({ ...mg, key: mg.id }))}
        pagination={pool.mortgages.length > 10 && 'bottom'}
      />
    </PoolStyled>
  )
}

export default Pool
